import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {
  LandscapeCard, PortraitCard, LandscapeCardList, PortraitCardList, CardStyled, HCard, HCardLeftImaged,
} from '../../../components';
import Vertical from '../../../components/EmotionCard/Vertical';
import Underlined from '../../../components/EmotionCard/Underlined';

const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor: 'white',
  },

}));

const LatestNews = (props) => {
  const { posts } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Hidden only={['md', 'lg', 'xl']}>
        <Grid container>
          <Grid item xs={12}>
            <PortraitCardList>
              <Grid container>
                {posts.map((post, idx) => (
                  <Grid item xs={6}>
                    {idx % 2 === 0 ? <HCard post={post.node} /> : (
                      <Vertical><HCard post={post.node} /></Vertical>
                    )}
                  </Grid>
                ))}
              </Grid>
            </PortraitCardList>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={['xs', 'sm']}>
        <Grid container>
          <Grid item xs={12}>
            <PortraitCardList>
              <Grid container>
                {posts.map((post, idx) => (
                  <Grid item xs={6}>
                    {idx % 2 === 0 ? <HCard post={post.node} /> : (
                      <Vertical right={-1.2}><HCard post={post.node} /></Vertical>
                    )}
                  </Grid>
                ))}
              </Grid>
            </PortraitCardList>
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
};
export default LatestNews;
