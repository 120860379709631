import React from 'react';
import { Link } from 'gatsby';

import Grid from '@material-ui/core/Grid';
import Dashboard from '@material-ui/icons/Dashboard';
import Schedule from '@material-ui/icons/Schedule';
import List from '@material-ui/icons/List';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import {
  MyTabs, ContentCarousel, ContentRow, RowTriple, NavPills,
  LandscapeCard, PortraitCard, LandscapeCardList, PortraitCardList,
  Opinion,
} from '../components';

import BlogCategoriesList from '../components/TabletDisclosure/blog-categories-list';

// @material-ui/icons
import ProfilePage from '../views/ProfilePage/ProfilePage';
import HomePage from '../views/HomePage/Home';

const categories = [
  {
    id: '4de945be-8b4c-54be-9eae-162778d938d2',
    slug: 'books/2020/05/16/book3',
    title: 'الكتاب يقرأ من غلافه او ماشابه ذلك',
    excerpt: 'أعربع تعلى الاستخاص فيا كل هنا على العام موضوعة الى علوك الم في قدمات الحدث - الموقع الجزائيستركيا للإذاع البالبية تركيا، مسانية الدية اق ا…',
    category: 'books',
    featuredImage: {
      childImageSharp: {
        fluid: {
          base64: 'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAHDQ0Ca/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECEhP/2gAIAQEAAQUC60nux1R//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAKRMjH/2gAIAQEABj8C61NNTTU//8QAGBAAAwEBAAAAAAAAAAAAAAAAABEhAaH/2gAIAQEAAT8hidhdaRqg/9oADAMBAAIAAwAAABB0H//EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxBrn//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxBCy//EABoQAQEAAgMAAAAAAAAAAAAAAAERANEhMXH/2gAIAQEAAT8QSKKUmRKCpe+8q8z3vP/Z',
        },
      },
    },
  }];

const IndexPage = ({ props }) => (
  <HomePage />
);

export default IndexPage;
