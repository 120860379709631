import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {
  PortraitCard, LandscapeCardList,
} from '../../../components';

const LatestNews = (props) => {
  const { posts } = props;
  let featuredPost;
  try {
    featuredPost = posts[0].node;
  } catch (error) {
    featuredPost = null;
  }

  return (

    <Grid container>
      <Grid item xs={12}>

        <LandscapeCardList>
          <PortraitCard
            {...featuredPost}
            featured="featured"
            basePath={`/${featuredPost.slug}`}
          />

          {posts.map((post, idx) => (

            <PortraitCard
              key={idx}

              {...post.node}
              basePath={`/${post.node.slug}`}
            />
          ))}
        </LandscapeCardList>
      </Grid>

    </Grid>
  );
};

LatestNews.propTypes = {
  posts: PropTypes.func.isRequired,
};

export default LatestNews;
