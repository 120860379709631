import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import HCard from '../../../components/EmotionCard/HCard';
import HCardLeftImaged from '../../../components/EmotionCard/HCardLeftImaged';
import Underlined from '../../../components/EmotionCard/Underlined';
import Vertical from '../../../components/EmotionCard/Vertical';
import { PortraitCard } from '../../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

const Row = (props) => {
  const classes = useStyles();
  const { posts } = props;
  const wholePosts = [...chunks(posts, 4)];
  return (
    <div className={classes.root}>

      {wholePosts.map((subarray, idx) => (
        <Underlined>
          <Grid container>
            {subarray.map(({ node: post }, index) => (

              <Grid item xs={6} md={3}>
                {/* <HCard post={post.node} /> */}
                {(index % 4 === 0)
                  ? (
                    <PortraitCard
                      {...post}
                      featured="featured"
                      basePath={`/${post.slug}`}
                    />
                  )
                  : (
                    <Vertical>
                      <PortraitCard
                        {...post}
                        featured="featured"
                        basePath={`/${post.slug}`}
                      />
                    </Vertical>
                  )}
              </Grid>
            ))}

          </Grid>
        </Underlined>
      ))}
    </div>
  );
};

export default Row;
